<template>
  <svg
    width="25"
    :height="size"
    viewBox="0 0 25 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1242_122)">
      <path
        d="M5.4 16.26H0.5C0.367392 16.26 0.240215 16.3127 0.146447 16.4065C0.0526784 16.5002 0 16.6274 0 16.76C0 16.8926 0.0526784 17.0198 0.146447 17.1136C0.240215 17.2073 0.367392 17.26 0.5 17.26H5.4C5.53261 17.26 5.65979 17.2073 5.75355 17.1136C5.84732 17.0198 5.9 16.8926 5.9 16.76C5.9 16.6274 5.84732 16.5002 5.75355 16.4065C5.65979 16.3127 5.53261 16.26 5.4 16.26Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M5.4 13.84H2.5C2.36739 13.84 2.24021 13.8926 2.14645 13.9864C2.05268 14.0802 2 14.2074 2 14.34C2 14.4726 2.05268 14.5998 2.14645 14.6935C2.24021 14.7873 2.36739 14.84 2.5 14.84H5.4C5.53261 14.84 5.65979 14.7873 5.75355 14.6935C5.84732 14.5998 5.9 14.4726 5.9 14.34C5.9 14.2074 5.84732 14.0802 5.75355 13.9864C5.65979 13.8926 5.53261 13.84 5.4 13.84Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M5.4 11.42H4.5C4.43482 11.4187 4.37002 11.4304 4.30941 11.4544C4.2488 11.4784 4.1936 11.5143 4.14704 11.56C4.10047 11.6056 4.06348 11.6601 4.03823 11.7202C4.01298 11.7803 3.99999 11.8448 4 11.91C4 12.0426 4.05268 12.1698 4.14645 12.2636C4.24021 12.3573 4.36739 12.41 4.5 12.41H5.4C5.53261 12.41 5.65979 12.3573 5.75355 12.2636C5.84732 12.1698 5.9 12.0426 5.9 11.91C5.90001 11.8448 5.88702 11.7803 5.86177 11.7202C5.83652 11.6601 5.79953 11.6056 5.75296 11.56C5.7064 11.5143 5.6512 11.4784 5.59059 11.4544C5.52998 11.4304 5.46518 11.4187 5.4 11.42Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M23.14 1.55998C22.1887 0.612826 20.9156 0.0587081 19.5741 0.00796938C18.2327 -0.0427693 16.9213 0.413594 15.9012 1.28618C14.8811 2.15878 14.227 3.38357 14.0693 4.71668C13.9115 6.0498 14.2617 7.39341 15.05 8.47998H7.46C7.39419 8.47942 7.32903 8.49298 7.26891 8.51976C7.2088 8.54653 7.15513 8.58589 7.11152 8.63518C7.06792 8.68447 7.0354 8.74254 7.01616 8.80548C6.99692 8.86841 6.99141 8.93474 7 8.99998V19.76C7 19.882 7.04846 19.999 7.13473 20.0853C7.22099 20.1715 7.338 20.22 7.46 20.22H21.64C21.7612 20.2174 21.8767 20.1681 21.9624 20.0824C22.0482 19.9967 22.0974 19.8812 22.1 19.76V9.90998C22.4621 9.68912 22.7976 9.42741 23.1 9.12998C24.105 8.12921 24.6732 6.7715 24.6807 5.35323C24.6882 3.93496 24.1343 2.57132 23.14 1.55998ZM14.06 9.40998V13.9H7.93V9.40998H14.06ZM7.93 14.8H14.06V19.29H7.93V14.8ZM14.93 19.29V14.8H21.15V19.29H14.93ZM21.15 12.23V13.9H15V9.40998H16C16.9545 10.2313 18.1708 10.6852 19.43 10.69C20.0478 10.6851 20.6602 10.5735 21.24 10.36L21.15 12.23ZM16.25 8.42998C15.6408 7.82 15.2261 7.04311 15.0584 6.19751C14.8906 5.35191 14.9773 4.47555 15.3074 3.6792C15.6376 2.88284 16.1964 2.20224 16.9133 1.72341C17.6302 1.24458 18.4729 0.989013 19.335 0.989013C20.1971 0.989013 21.0398 1.24458 21.7567 1.72341C22.4736 2.20224 23.0324 2.88284 23.3626 3.6792C23.6927 4.47555 23.7794 5.35191 23.6116 6.19751C23.4439 7.04311 23.0292 7.82 22.42 8.42998C21.5992 9.23555 20.4951 9.68686 19.345 9.68686C18.1949 9.68686 17.0908 9.23555 16.27 8.42998H16.25Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M21.47 3.50003C21.4459 3.43895 21.4095 3.38347 21.363 3.33703C21.3166 3.29058 21.2611 3.25417 21.2 3.23003C21.1375 3.21515 21.0725 3.21515 21.01 3.23003H18.39C18.2574 3.23003 18.1302 3.28271 18.0365 3.37648C17.9427 3.47024 17.89 3.59742 17.89 3.73003C17.89 3.86264 17.9427 3.98982 18.0365 4.08358C18.1302 4.17735 18.2574 4.23003 18.39 4.23003H19.82L17.35 6.65003C17.3022 6.69492 17.2641 6.74914 17.238 6.80934C17.2119 6.86953 17.1985 6.93443 17.1985 7.00003C17.1985 7.06563 17.2119 7.13053 17.238 7.19072C17.2641 7.25092 17.3022 7.30514 17.35 7.35003C17.3947 7.39807 17.4489 7.43623 17.5091 7.46206C17.5694 7.4879 17.6344 7.50083 17.7 7.50003C17.8319 7.49815 17.9577 7.44424 18.05 7.35003L20.52 4.88003V6.31003C20.52 6.44264 20.5727 6.56982 20.6665 6.66358C20.7602 6.75735 20.8874 6.81003 21.02 6.81003C21.1526 6.81003 21.2798 6.75735 21.3736 6.66358C21.4673 6.56982 21.52 6.44264 21.52 6.31003V3.67003C21.5191 3.60986 21.5019 3.55108 21.47 3.50003Z"
        :fill="color"
        class="svg-icon-fill"
      />
    </g>
    <defs>
      <clipPath id="clip0_1242_122">
        <rect
          width="24.7"
          height="20.22"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '21',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>