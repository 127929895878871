<template>
  <div class="order-status">
    <component :is="`icon-${statusIcon}`" />
    <span v-if="!hideText">{{ $t(`langkey.${statusText}`) }}</span>
  </div>
</template>

<script>
import IconOrderStatusConfirm from '@/components/icons/IconOrderStatusConfirm';
import IconOrderStatusSent from '@/components/icons/IconOrderStatusSent';
import IconOrderStatusInvoice from '@/components/icons/IconOrderStatusInvoice';
import IconOrderStatusCancel from '@/components/icons/IconOrderStatusCancel';

export default {
  components: {
    IconOrderStatusConfirm,
    IconOrderStatusSent,
    IconOrderStatusInvoice,
    IconOrderStatusCancel,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hideText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusIcon() {
      if (this.item.status === 2) {
        return 'order-status-sent';
      } else if (this.item.status === 3) {
        return 'order-status-invoice';
      } else if (this.item.status === 4) {
        return 'order-status-cancel';
      } else {
        return 'order-status-confirm';
      }
    },
    statusText() {
      if (this.item.status === 2) {
        return 'sent';
      } else if (this.item.status === 3) {
        return 'invoiced';
      } else if (this.item.status === 4) {
        return 'canceled';
      } else {
        return 'ordered';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-status {
  display: flex;
  align-items: center;
  color: var(--theme-primary);
  span {
    margin-left: 4px;
  }
}
</style>