<template>
  <svg
    width="23"
    :height="size"
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1242_98)">
      <path
        d="M20.86 2.09997C19.787 1.02466 18.4006 0.317388 16.9001 0.0798825C15.3997 -0.157623 13.8626 0.0868771 12.51 0.778216C11.1573 1.46956 10.0588 2.57211 9.37234 3.92728C8.68593 5.28246 8.44703 6.82043 8.69 8.31997H0.47C0.408756 8.31864 0.347862 8.32955 0.290893 8.35207C0.233924 8.37459 0.182027 8.40826 0.138247 8.45111C0.0944672 8.49396 0.0596869 8.54512 0.0359469 8.60159C0.0122069 8.65807 -1.4464e-05 8.71871 1.28465e-08 8.77997V19.59C0.00261572 19.7129 0.0532858 19.8299 0.141146 19.9159C0.229006 20.0019 0.347062 20.05 0.47 20.05H13.64C13.7629 20.05 13.881 20.0019 13.9689 19.9159C14.0567 19.8299 14.1074 19.7129 14.11 19.59V14.18C14.6603 14.3117 15.2241 14.3788 15.79 14.38C17.2121 14.3784 18.6017 13.9551 19.7832 13.1637C20.9647 12.3723 21.885 11.2483 22.4277 9.93382C22.9704 8.61938 23.1111 7.17354 22.832 5.77912C22.553 4.3847 21.8667 3.10435 20.86 2.09997ZM10.69 12.27C11.3072 12.887 12.0326 13.3853 12.83 13.74H7.5V9.24997H8.89C9.23085 10.3901 9.84933 11.4278 10.69 12.27ZM6.6 9.26997V13.76H0.93V9.24997L6.6 9.26997ZM0.93 14.63H6.6V19.12H0.93V14.63ZM7.5 19.12V14.63H13.18V19.12H7.5ZM20.15 11.56C19.2845 12.4262 18.1816 13.0163 16.9807 13.2556C15.7798 13.4949 14.5349 13.3727 13.4035 12.9043C12.2721 12.436 11.305 11.6426 10.6246 10.6245C9.9442 9.60647 9.58102 8.40948 9.58102 7.18497C9.58102 5.96046 9.9442 4.76347 10.6246 3.7454C11.305 2.72734 12.2721 1.93396 13.4035 1.46562C14.5349 0.997276 15.7798 0.875022 16.9807 1.11432C18.1816 1.35362 19.2845 1.94372 20.15 2.80997C20.7254 3.38402 21.1819 4.06594 21.4934 4.81667C21.8048 5.56739 21.9652 6.37219 21.9652 7.18497C21.9652 7.99775 21.8048 8.80254 21.4934 9.55327C21.1819 10.304 20.7254 10.9859 20.15 11.56Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M18.42 4.55C18.3735 4.50313 18.3182 4.46594 18.2573 4.44055C18.1964 4.41517 18.131 4.4021 18.065 4.4021C17.999 4.4021 17.9337 4.41517 17.8727 4.44055C17.8118 4.46594 17.7565 4.50313 17.71 4.55L15.78 6.48L13.84 4.55C13.7935 4.50313 13.7382 4.46594 13.6773 4.44055C13.6164 4.41517 13.551 4.4021 13.485 4.4021C13.419 4.4021 13.3537 4.41517 13.2927 4.44055C13.2318 4.46594 13.1765 4.50313 13.13 4.55C13.0384 4.64346 12.9871 4.76912 12.9871 4.9C12.9871 5.03088 13.0384 5.15653 13.13 5.25L15.07 7.19L13.13 9.12C13.0378 9.21515 12.9862 9.34247 12.9862 9.475C12.9862 9.60753 13.0378 9.73485 13.13 9.83C13.2286 9.91946 13.3569 9.96931 13.49 9.97C13.6201 9.96875 13.745 9.9188 13.84 9.83L15.78 7.89L17.71 9.83C17.8051 9.9188 17.9299 9.96875 18.06 9.97C18.1932 9.96931 18.3214 9.91946 18.42 9.83C18.5123 9.73485 18.5639 9.60753 18.5639 9.475C18.5639 9.34247 18.5123 9.21515 18.42 9.12L16.48 7.19L18.42 5.25C18.5116 5.15653 18.5629 5.03088 18.5629 4.9C18.5629 4.76912 18.5116 4.64346 18.42 4.55Z"
        :fill="color"
        class="svg-icon-fill"
      />
    </g>
    <defs>
      <clipPath id="clip0_1242_98">
        <rect
          width="22.96"
          height="20.05"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '21',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>