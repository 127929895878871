<template>
  <svg
    width="21"
    :height="size"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1242_111)">
      <path
        d="M15.43 18.41C15.3 18.41 15.1754 18.4616 15.0835 18.5535C14.9916 18.6454 14.94 18.77 14.94 18.9V19.26H1V0.99999H15V12.61C15 12.7426 15.0527 12.8698 15.1464 12.9635C15.2402 13.0573 15.3674 13.11 15.5 13.11C15.6326 13.11 15.7598 13.0573 15.8536 12.9635C15.9473 12.8698 16 12.7426 16 12.61V0.48999C16.001 0.418321 15.9862 0.347314 15.9567 0.281975C15.9273 0.216636 15.8838 0.158557 15.8295 0.111833C15.7751 0.0651089 15.7112 0.0308777 15.6422 0.0115533C15.5731 -0.00777117 15.5007 -0.0117183 15.43 -9.73465e-06H0.49C0.360044 -9.73465e-06 0.23541 0.0516151 0.143518 0.143508C0.0516248 0.235401 0 0.360034 0 0.48999L0 19.75C0 19.8799 0.0516248 20.0046 0.143518 20.0965C0.23541 20.1884 0.360044 20.24 0.49 20.24H15.43C15.4943 20.24 15.5581 20.2273 15.6175 20.2027C15.677 20.1781 15.731 20.142 15.7765 20.0965C15.822 20.051 15.8581 19.997 15.8827 19.9375C15.9073 19.8781 15.92 19.8143 15.92 19.75V18.9C15.92 18.8356 15.9073 18.7719 15.8827 18.7125C15.8581 18.653 15.822 18.599 15.7765 18.5535C15.731 18.508 15.677 18.4719 15.6175 18.4473C15.5581 18.4227 15.4943 18.41 15.43 18.41Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M12.57 3.18994H3.35001C3.2174 3.18994 3.09022 3.24262 2.99645 3.33639C2.90268 3.43016 2.85001 3.55733 2.85001 3.68994C2.85001 3.82255 2.90268 3.94973 2.99645 4.04349C3.09022 4.13726 3.2174 4.18994 3.35001 4.18994H12.57C12.7026 4.18994 12.8298 4.13726 12.9236 4.04349C13.0173 3.94973 13.07 3.82255 13.07 3.68994C13.07 3.55733 13.0173 3.43016 12.9236 3.33639C12.8298 3.24262 12.7026 3.18994 12.57 3.18994Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M12.57 5.81995H3.34999C3.22003 5.81995 3.0954 5.87157 3.0035 5.96346C2.91161 6.05536 2.85999 6.17999 2.85999 6.30995C2.85997 6.37383 2.87271 6.43708 2.89746 6.49597C2.9222 6.55487 2.95845 6.60824 3.00409 6.65294C3.04973 6.69765 3.10383 6.73279 3.16322 6.75632C3.22262 6.77984 3.28611 6.79128 3.34999 6.78995H12.57C12.6334 6.79131 12.6964 6.77983 12.7553 6.75619C12.8141 6.73255 12.8676 6.69724 12.9124 6.6524C12.9573 6.60755 12.9926 6.55409 13.0162 6.49524C13.0399 6.43638 13.0513 6.37335 13.05 6.30995C13.0513 6.24608 13.0399 6.18258 13.0164 6.12319C12.9928 6.06379 12.9577 6.00969 12.913 5.96405C12.8683 5.91842 12.8149 5.88216 12.756 5.85742C12.6971 5.83267 12.6339 5.81993 12.57 5.81995Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M4.50001 8.45996H3.35001C3.2174 8.45996 3.09022 8.51264 2.99645 8.60641C2.90268 8.70018 2.85001 8.82735 2.85001 8.95996C2.85001 9.09257 2.90268 9.21975 2.99645 9.31351C3.09022 9.40728 3.2174 9.45996 3.35001 9.45996H4.50001C4.63261 9.45996 4.75979 9.40728 4.85356 9.31351C4.94733 9.21975 5.00001 9.09257 5.00001 8.95996C5.00001 8.82735 4.94733 8.70018 4.85356 8.60641C4.75979 8.51264 4.63261 8.45996 4.50001 8.45996Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M12.57 8.45996H7.42999C7.29738 8.45996 7.17021 8.51264 7.07644 8.60641C6.98267 8.70018 6.92999 8.82735 6.92999 8.95996C6.92999 9.09257 6.98267 9.21975 7.07644 9.31351C7.17021 9.40728 7.29738 9.45996 7.42999 9.45996H12.57C12.7026 9.45996 12.8298 9.40728 12.9235 9.31351C13.0173 9.21975 13.07 9.09257 13.07 8.95996C13.07 8.82735 13.0173 8.70018 12.9235 8.60641C12.8298 8.51264 12.7026 8.45996 12.57 8.45996Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M4.49999 11.1H3.34999C3.22003 11.1 3.0954 11.1516 3.0035 11.2435C2.91161 11.3354 2.85999 11.46 2.85999 11.59C2.85997 11.6538 2.87271 11.7171 2.89746 11.776C2.9222 11.8349 2.95845 11.8882 3.00409 11.933C3.04973 11.9777 3.10383 12.0128 3.16322 12.0363C3.22262 12.0599 3.28611 12.0713 3.34999 12.07H4.49999C4.56468 12.0727 4.62926 12.0622 4.68982 12.0393C4.75039 12.0164 4.80569 11.9815 4.8524 11.9366C4.89911 11.8918 4.93627 11.838 4.96163 11.7784C4.987 11.7188 5.00004 11.6547 4.99999 11.59C5 11.5248 4.987 11.4602 4.96175 11.4001C4.93651 11.34 4.89952 11.2855 4.85295 11.2399C4.80639 11.1943 4.75118 11.1584 4.69057 11.1343C4.62996 11.1103 4.56517 11.0986 4.49999 11.1Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M12.57 11.1H7.43C7.30005 11.1 7.17541 11.1516 7.08352 11.2435C6.99163 11.3354 6.94 11.46 6.94 11.59C6.93999 11.6539 6.95273 11.7171 6.97747 11.776C7.00222 11.8349 7.03847 11.8883 7.08411 11.933C7.12974 11.9777 7.18385 12.0128 7.24324 12.0363C7.30264 12.0599 7.36613 12.0713 7.43 12.07H12.57C12.6334 12.0713 12.6964 12.0599 12.7553 12.0362C12.8141 12.0126 12.8676 11.9773 12.9125 11.9324C12.9573 11.8876 12.9926 11.8341 13.0162 11.7753C13.0399 11.7164 13.0514 11.6534 13.05 11.59C13.0513 11.5261 13.0399 11.4626 13.0164 11.4032C12.9928 11.3438 12.9577 11.2897 12.913 11.2441C12.8683 11.1984 12.8149 11.1622 12.756 11.1374C12.6971 11.1127 12.6339 11.1 12.57 11.1Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M20.34 10.56C20.2951 10.5121 20.2409 10.474 20.1807 10.448C20.1205 10.4219 20.0556 10.4084 19.99 10.4084C19.9244 10.4084 19.8595 10.4219 19.7993 10.448C19.7391 10.474 19.6849 10.5121 19.64 10.56L14.94 15.62L12.94 13.62C12.8944 13.5734 12.8399 13.5364 12.7798 13.5112C12.7197 13.4859 12.6552 13.4729 12.59 13.4729C12.5248 13.4729 12.4603 13.4859 12.4002 13.5112C12.3401 13.5364 12.2856 13.5734 12.24 13.62C12.1931 13.6664 12.1559 13.7217 12.1306 13.7827C12.1052 13.8436 12.0921 13.909 12.0921 13.975C12.0921 14.041 12.1052 14.1063 12.1306 14.1673C12.1559 14.2282 12.1931 14.2835 12.24 14.33L14.63 16.74C14.6746 16.788 14.7289 16.8262 14.7891 16.852C14.8494 16.8778 14.9144 16.8908 14.98 16.89C15.0479 16.8903 15.1151 16.8762 15.1772 16.8486C15.2392 16.821 15.2947 16.7806 15.34 16.73L20.39 11.29C20.4369 11.2399 20.473 11.1806 20.496 11.116C20.5191 11.0513 20.5285 10.9826 20.5238 10.9141C20.5191 10.8456 20.5004 10.7788 20.4688 10.7179C20.4371 10.657 20.3933 10.6032 20.34 10.56Z"
        :fill="color"
        class="svg-icon-fill"
      />
    </g>
    <defs>
      <clipPath id="clip0_1242_111">
        <rect
          width="20.5"
          height="20.24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '21',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>