<template>
  <svg
    width="25"
    :height="size"
    viewBox="0 0 25 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1242_103)">
      <path
        d="M10.53 20.5C10.1602 20.5 9.79861 20.3903 9.49109 20.1849C9.18357 19.9794 8.94389 19.6873 8.80235 19.3456C8.66081 19.0039 8.62378 18.6279 8.69594 18.2652C8.76809 17.9024 8.94619 17.5692 9.20771 17.3077C9.46924 17.0462 9.80244 16.8681 10.1652 16.7959C10.5279 16.7238 10.9039 16.7608 11.2456 16.9024C11.5873 17.0439 11.8794 17.2836 12.0849 17.5911C12.2903 17.8986 12.4 18.2602 12.4 18.63C12.4 19.126 12.203 19.6016 11.8523 19.9523C11.5016 20.303 11.026 20.5 10.53 20.5ZM10.53 17.76C10.3579 17.76 10.1897 17.811 10.0467 17.9066C9.90359 18.0022 9.79208 18.1381 9.72623 18.2971C9.66038 18.456 9.64315 18.631 9.67672 18.7997C9.71029 18.9685 9.79315 19.1235 9.91482 19.2452C10.0365 19.3669 10.1915 19.4497 10.3603 19.4833C10.529 19.5169 10.704 19.4996 10.8629 19.4338C11.0219 19.3679 11.1578 19.2564 11.2534 19.1134C11.349 18.9703 11.4 18.8021 11.4 18.63C11.4 18.3993 11.3083 18.178 11.1452 18.0148C10.982 17.8517 10.7607 17.76 10.53 17.76V17.76Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M15.75 20.5C15.3802 20.5 15.0186 20.3903 14.7111 20.1849C14.4036 19.9794 14.1639 19.6873 14.0224 19.3456C13.8808 19.0039 13.8438 18.6279 13.9159 18.2652C13.9881 17.9024 14.1662 17.5692 14.4277 17.3077C14.6892 17.0462 15.0224 16.8681 15.3852 16.7959C15.7479 16.7238 16.1239 16.7608 16.4656 16.9024C16.8073 17.0439 17.0994 17.2836 17.3049 17.5911C17.5103 17.8986 17.62 18.2602 17.62 18.63C17.62 19.126 17.423 19.6016 17.0723 19.9523C16.7216 20.303 16.246 20.5 15.75 20.5V20.5ZM15.75 17.76C15.5779 17.76 15.4097 17.811 15.2667 17.9066C15.1236 18.0022 15.0121 18.1381 14.9462 18.2971C14.8804 18.456 14.8632 18.631 14.8967 18.7997C14.9303 18.9685 15.0132 19.1235 15.1348 19.2452C15.2565 19.3669 15.4115 19.4497 15.5803 19.4833C15.749 19.5169 15.924 19.4996 16.0829 19.4338C16.2419 19.3679 16.3778 19.2564 16.4734 19.1134C16.569 18.9703 16.62 18.8021 16.62 18.63C16.62 18.3993 16.5283 18.178 16.3652 18.0148C16.202 17.8517 15.9807 17.76 15.75 17.76V17.76Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M5.25 2.62L4.26 0H0.5C0.367392 0 0.240215 0.0526784 0.146447 0.146447C0.0526784 0.240215 0 0.367392 0 0.5C0 0.632608 0.0526784 0.759785 0.146447 0.853553C0.240215 0.947322 0.367392 1 0.5 1H3.57L4.31 3C4.34772 3.09397 4.41257 3.17459 4.49627 3.23158C4.57997 3.28856 4.67874 3.31935 4.78 3.32C4.83962 3.32953 4.90038 3.32953 4.96 3.32C5.02639 3.29487 5.08693 3.25642 5.13789 3.20701C5.18886 3.1576 5.22916 3.09827 5.25633 3.03269C5.2835 2.96712 5.29695 2.89667 5.29587 2.82569C5.29478 2.75472 5.27917 2.68471 5.25 2.62V2.62Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M8.14999 12.77H18C18.1053 12.7698 18.208 12.737 18.2939 12.6761C18.3799 12.6153 18.4449 12.5293 18.48 12.43L19 10.69C20.0287 10.6823 21.0333 10.3783 21.8936 9.81429C22.7539 9.25027 23.4334 8.45019 23.8506 7.50988C24.2678 6.56958 24.4051 5.52893 24.246 4.51261C24.0868 3.49628 23.6381 2.54738 22.9535 1.77957C22.2688 1.01177 21.3774 0.457625 20.3859 0.183525C19.3943 -0.0905759 18.3448 -0.0730086 17.363 0.234122C16.3813 0.541253 15.5088 1.12492 14.8503 1.91521C14.1917 2.70549 13.775 3.66888 13.65 4.68997H5.71999C5.64209 4.68833 5.56497 4.70569 5.49528 4.74054C5.42559 4.77538 5.36543 4.82667 5.31999 4.88997C5.27495 4.95475 5.24524 5.02893 5.23311 5.10689C5.22098 5.18486 5.22675 5.26456 5.24999 5.33997L7.40999 11.94C6.91838 12.116 6.50457 12.4597 6.24129 12.9107C5.97801 13.3617 5.88213 13.891 5.97049 14.4056C6.05886 14.9203 6.32581 15.3873 6.72443 15.7246C7.12304 16.062 7.62781 16.248 8.14999 16.25H18.49C18.6218 16.2474 18.7475 16.1939 18.8407 16.1007C18.9339 16.0075 18.9874 15.8818 18.99 15.75C18.99 15.6174 18.9373 15.4902 18.8435 15.3964C18.7498 15.3026 18.6226 15.25 18.49 15.25H8.14999C7.82643 15.25 7.51611 15.1214 7.28732 14.8926C7.05853 14.6638 6.92999 14.3535 6.92999 14.03C6.92999 13.7064 7.05853 13.3961 7.28732 13.1673C7.51611 12.9385 7.82643 12.81 8.14999 12.81V12.77ZM18.92 0.999965C19.7827 0.999968 20.6261 1.25593 21.3433 1.73546C22.0605 2.21498 22.6194 2.89651 22.9491 3.69378C23.2788 4.49105 23.3645 5.36822 23.1955 6.21424C23.0264 7.06027 22.6102 7.83713 21.9994 8.44649C21.3887 9.05585 20.6109 9.4703 19.7644 9.63741C18.918 9.80451 18.0411 9.71674 17.2446 9.3852C16.448 9.05367 15.7678 8.49328 15.2899 7.77497C14.812 7.05666 14.558 6.21272 14.56 5.34997C14.5626 4.19536 15.0232 3.08894 15.8405 2.27344C16.6579 1.45795 17.7654 0.999962 18.92 0.999965V0.999965ZM13.6 5.63997C13.6569 6.84434 14.1198 7.99399 14.9133 8.90184C15.7067 9.80969 16.7841 10.4223 17.97 10.64L17.6 11.81H8.40999L6.40999 5.67997L13.6 5.63997Z"
        :fill="color"
        class="svg-icon-fill"
      />
      <path
        d="M18.14 7.38991C18.1846 7.43795 18.2388 7.47611 18.2991 7.50194C18.3594 7.52778 18.4244 7.54071 18.49 7.53991V7.53991C18.5562 7.53883 18.6215 7.52406 18.6817 7.49652C18.742 7.46899 18.7958 7.42928 18.84 7.37991L21.64 4.37991C21.709 4.2806 21.7397 4.15966 21.7263 4.03947C21.713 3.91929 21.6566 3.808 21.5675 3.72624C21.4784 3.64447 21.3627 3.59776 21.2418 3.59476C21.1209 3.59176 21.003 3.63267 20.91 3.70991L18.48 6.32991L17 4.78991C16.9048 4.69766 16.7775 4.64607 16.645 4.64607C16.5125 4.64607 16.3851 4.69766 16.29 4.78991C16.1984 4.88337 16.1471 5.00903 16.1471 5.13991C16.1471 5.27079 16.1984 5.39644 16.29 5.48991L18.14 7.38991Z"
        :fill="color"
        class="svg-icon-fill"
      />
    </g>
    <defs>
      <clipPath id="clip0_1242_103">
        <rect
          width="24.27"
          height="20.5"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '21',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>