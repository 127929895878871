<template>
  <v-btn
    v-if="button"
    small
    color="secondary"
    outlined
    @click="downloadPdf($event, id)"
  >
    <icon-pdf />
    <span>{{ number }}</span>
  </v-btn>
  <a
    v-else
    href="#"
    target="_blank"
    rel="nofollow"
    class="d-flex flex-column align-center"
    style="text-decoration: none;"
    @click="downloadPdf($event, id)"
  >
    <icon-pdf />
    <span>{{ number }}</span>
  </a>
</template>

<script>
import {OrderService} from '@/services';
import IconPdf from '@/components/icons/IconPdf';

export default {
  name: 'DownloadInvoice',
  components: {
    IconPdf,
  },
  props: {
    number: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    button: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async downloadPdf(event, id) {
      event.preventDefault();
      try {
        const response = await OrderService.getPdf(id);
        const pdf = response.data;
        let anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.href = window.URL.createObjectURL(pdf);
        anchor.target = '_blank';
        anchor.download = `${this.number}.pdf`;
        anchor.click();
        document.body.removeChild(anchor);
      } catch (err) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Download PDF Error',
          message: err,
        });
      }
    },
  },
};
</script>
